import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { History, KeyboardArrowRight } from '@material-ui/icons'
import EventIcon from '@material-ui/icons/Event'
import InfoIcon from '@material-ui/icons/Info'
import PersonIcon from '@material-ui/icons/Person'
import { Skeleton } from '@material-ui/lab'
import Timeline from '@material-ui/lab/Timeline/Timeline'
import TimelineConnector from '@material-ui/lab/TimelineConnector/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot/TimelineDot'
import TimelineItem from '@material-ui/lab/TimelineItem/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator/TimelineSeparator'
import { withStyles } from '@material-ui/styles'
import { ContentWrapper } from 'components/Layout/ContentWrapper'
import { WorkflowHelper } from 'containers/helper/WorkFlowHelper'
import {
  ApprovalStatus,
  useGetAttendanceCorrectionToDoQuery,
} from 'generated/graphql'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import Approvalflow from '../../../../src/assets/icons/approvalflow.svg'
import './style.scss'

const dateFormat = require('dateformat')

const CustomTimelineItem = withStyles({
  missingOppositeContent: {
    '&:before': {
      display: 'none',
    },
  },
})(TimelineItem)

export const TodoAttendanceCorrectionRequest = (props: any) => {
  const history = useHistory()
  const EmployeeDetails = JSON.parse(localStorage.getItem('loggedInEmployee'))
  const Employee = JSON.parse(localStorage.getItem('Employee'))
  const [TodoListing, setTodoListing] = useState([])
  const [WorkFlowData, setWorkFlowData] = useState(null)
  const [openWorkFlow, setWorkFlow] = useState(false)
  // const handleView = (link: any) => {
  //   window.open(link, '_blank')
  // }

  const { loading } = useGetAttendanceCorrectionToDoQuery({
    variables: {
      EmployeeID: EmployeeDetails?.employeeID,
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data.GetAttendanceCorrectionToDo) {
        setTodoListing(data.GetAttendanceCorrectionToDo)
      }
    },
  })

  const WorkFlowClicked = (ID: string) => {
    let Data = TodoListing?.find(x => x.attendance_correction_header_id === ID)
    setWorkFlowData(Data)
    // setWorkFlowIndex(index)
    setWorkFlow(true)
  }

  const pendingStepIndex = WorkFlowData?.WorkFlow?.WorkFlowStep?.findIndex(
    step => step.ApprovalStatus === 'Pending'
  )

  const TodoItem = TodoListing?.map((v, index) => {
    let workFlow = WorkflowHelper(
      v?.WorkFlow?.WorkFlowStep,
      EmployeeDetails?.employeeID
    )

    return (
      <ListItem
        key={index}
        style={
          workFlow?.CurrentApproverStepApproved &&
          !workFlow?.IsCurrentApproveApproved
            ? { display: 'none' }
            : {}
        }
      >
        <ListItemText
          primary={
            <>
              <div className="flex-row">
                <div className="xsTitle flex-spaces">
                  Attendance Correction Request
                </div>
                <div className="desc">
                  {dateFormat(new Date(v?.created_dt), 'dd mmm yyyy')}
                </div>
              </div>
              <div className="flex-row">
                <div className="desc flex-spaces">
                  {v?.Employee?.Contact?.FullName} | {v?.Employee?.EmployeeNo}
                </div>
              </div>
            </>
          }
          secondary={
            <div
              className="icon-text"
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <span
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <span className={`desc flex-space`}>
                  <>{dateFormat(new Date(v?.date), 'dd mmm yyyy')}</>
                </span>
                <span
                  className="desc"
                  style={{
                    fontSize: '10px',
                    color: 'orange',
                  }}
                  onClick={e => {
                    // e.stopPropagation()
                    WorkFlowClicked(v?.attendance_correction_header_id)
                  }}
                >
                  <img
                    className="desc"
                    src={Approvalflow}
                    style={{ width: '18px' }}
                  />
                  &nbsp; {v?.approval_status}
                </span>
              </span>
            </div>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => {
              history.push('/home/todo/attendancecorrectionrequest/details', {
                EmployeeName: v?.Employee?.Contact?.FullName,
                EmployeeNo: v?.Employee?.EmployeeNo,
                EmployeeID: v?.EmployeeID,
                RequestStatus: v?.approval_status,
                CreatedDT: v?.created_dt,
                Req: v,
              })
            }}
          >
            <KeyboardArrowRight />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  })

  const item = (
    <List
      className="core-list"
      style={{ marginBottom: '50px', marginTop: '31px' }}
    >
      {TodoItem}
    </List>
  )

  return (
    <>
      {/* {loading && <Loading />} */}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push('/home')}
        title={`${Employee?.Contact?.EmployeeSalutation?.Name || ''} ${
          Employee?.Contact?.FullName
        }`}
        smTitle={'Employee Super App'}
        routeSegments={[{ name: '' }, { name: 'Todo', current: true }]}
      />

      <DynamicSubHeader
        title={
          <div
            style={{
              width: '100%',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'space-between',
              flexDirection: 'row',
            }}
          >
            <span className="c-orange flex-space">
              Attendance Correction Request
            </span>
            <span className="c-orange">
              <History
                style={{
                  fontSize: '16px',
                }}
                onClick={() => {}}
              />
            </span>
          </div>
        }
      />

      <ContentWrapper>
        {loading ? (
          Array.from({ length: 10 }, (_, i) => (
            <Skeleton
              key={`skeleton-${i}`}
              animation="wave"
              variant="rect"
              height={100}
              style={{
                marginBottom: 5,
                borderRadius: 5,
              }}
            />
          ))
        ) : TodoListing?.length > 0 ? (
          item
        ) : (
          <EmptyList title="No Pending Appoval found" />
        )}
      </ContentWrapper>

      <CommonDialog
        fullWidth={true}
        open={openWorkFlow}
        onClose={() => setWorkFlow(false)}
        sections={{
          header: {
            dynamic: (
              <div className="">
                <div className="dialog-dynamic-content">
                  <div className="session">
                    {/* <div className="smTitle">SmTitle</div> */}
                    <div className="flex session">
                      <div className="title c-orange flex-space">
                        Attendance Correction Request
                      </div>
                      <div className="rightText c-orange">
                        {WorkFlowData?.approval_status}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="infoline-content">
                  <div className="xsTitle flex-space">
                    {`Attendance Correction Request Application`}
                  </div>
                  <div className=" xsTitle rightText">Approval Log</div>
                </div>
              </div>
            ),
          },
          body: () => (
            <>
              <Typography
                variant="subtitle1"
                style={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
              >{`Submitter`}</Typography>
              <PersonIcon style={{ fontSize: 'small' }} />
              <Typography variant="caption">
                {' '}
                {WorkFlowData?.WorkFlow?.SubmitterName ||
                  WorkFlowData?.SubmitterName ||
                  WorkFlowData?.Employee?.Contact?.FullName}{' '}
              </Typography>{' '}
              <br />
              <EventIcon style={{ fontSize: 'small' }} />
              <Typography variant="caption">
                {' '}
                {dateFormat(
                  new Date(
                    WorkFlowData?.WorkFlow?.SubmitDate ||
                      WorkFlowData?.SubmissionDate ||
                      WorkFlowData?.created_dt ||
                      new Date()
                  ),
                  'dd mmm yyyy h:MM TT'
                )}{' '}
              </Typography>{' '}
              <Divider orientation="horizontal" variant="fullWidth" />
              <br />
              <Timeline className="timeline">
                {WorkFlowData?.WorkFlow ? (
                  WorkFlowData?.WorkFlow?.WorkFlowStep.map((x, index) => (
                    <CustomTimelineItem>
                      <TimelineSeparator>
                        <TimelineDot
                          style={
                            x.ApprovalStatus === ApprovalStatus.Approved
                              ? {
                                  backgroundColor: 'green',
                                }
                              : x.ApprovalStatus === ApprovalStatus.Rejected ||
                                x.ApprovalStatus === ApprovalStatus.Cancelled
                              ? {
                                  backgroundColor: 'red',
                                }
                              : x.NotifiedDT
                              ? { backgroundColor: '#ffb11f' }
                              : {}
                          }
                        />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography variant="subtitle1">
                          {`Level ${x.StepNo}`} &nbsp;
                          <span
                            style={
                              x?.ApprovalStatus === 'Pending'
                                ? {
                                    color: '#ffb11f',
                                    fontSize: '12px',
                                  }
                                : x?.ApprovalStatus === 'Rejected' ||
                                  x?.ApprovalStatus === 'Cancelled'
                                ? {
                                    color: 'red',
                                    fontSize: '12px',
                                  }
                                : {
                                    color: 'green',
                                    fontSize: '12px',
                                  }
                            }
                          >
                            {x?.ApprovalStatus === ApprovalStatus.Withdraw
                              ? 'Approved'
                              : x?.ApprovalStatus}
                          </span>
                        </Typography>
                        {x?.WorkFlowApprovedStep?.length > 0 ? (
                          <>
                            <PersonIcon style={{ fontSize: 'small' }} />
                            <Typography variant="caption">
                              {' '}
                              {x?.WorkFlowApprovedStep[0]?.ApproverName}{' '}
                            </Typography>
                            <br />
                            <EventIcon style={{ fontSize: 'small' }} />
                            <Typography variant="caption">
                              {' '}
                              {dateFormat(
                                new Date(
                                  x?.WorkFlowApprovedStep[0]?.ApprovalDT
                                ),
                                'dd mmm yyyy h:MM TT'
                              )}{' '}
                            </Typography>
                            <br />
                            <Typography variant="caption">
                              {' '}
                              {x?.WorkFlowApprovedStep[0]?.StatusRemarks}{' '}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography variant="body2">
                              {' '}
                              {x?.RoleName}{' '}
                            </Typography>
                            {pendingStepIndex !== -1 &&
                            pendingStepIndex === index ? (
                              <>
                                {WorkFlowData?.WorkFlowCurrentApproversList ===
                                null ? (
                                  <>
                                    <span
                                      className="desc"
                                      style={{
                                        alignItems: 'center',
                                        display: 'flex',
                                      }}
                                    >
                                      <InfoIcon
                                        style={{
                                          color: 'red',
                                          fontSize: '15px',
                                        }}
                                      />
                                      &nbsp;
                                      <span
                                        className="desc"
                                        style={{ color: 'red' }}
                                      >
                                        No approver found! Please contact HR.
                                      </span>
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span
                                      className="desc"
                                      style={{
                                        alignItems: 'baseline',
                                        display: 'flex',
                                      }}
                                    >
                                      <PersonIcon
                                        style={{ fontSize: 'small' }}
                                      />
                                      &nbsp;
                                      <span
                                        style={{
                                          whiteSpace: 'normal',
                                          marginTop: '3px',
                                        }}
                                      >
                                        {WorkFlowData?.WorkFlowCurrentApproversList?.map(
                                          (v, index) => (
                                            <>
                                              <Typography variant="caption">
                                                {v?.name}
                                                {index ===
                                                WorkFlowData
                                                  ?.WorkFlowCurrentApproversList
                                                  ?.length -
                                                  1
                                                  ? ''
                                                  : ','}
                                              </Typography>
                                              &nbsp;
                                            </>
                                          )
                                        )}
                                      </span>
                                    </span>
                                  </>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </TimelineContent>
                    </CustomTimelineItem>
                  ))
                ) : WorkFlowData?.approval_status ===
                  ApprovalStatus.Submitted ? (
                  <CustomTimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant="subtitle1">
                        {`Level 1`} &nbsp;
                        <span
                          style={{
                            color: '#ffb11f',
                            fontSize: '12px',
                          }}
                        >
                          Pending
                        </span>
                      </Typography>

                      <>
                        <Typography variant="body2">Superior</Typography>

                        {WorkFlowData?.WorkFlowCurrentApproversList === null ? (
                          <>
                            <span
                              className="desc"
                              style={{
                                alignItems: 'center',
                                display: 'flex',
                                marginTop: '3px',
                              }}
                            >
                              <InfoIcon
                                style={{
                                  color: 'red',
                                  fontSize: '15px',
                                }}
                              />
                              &nbsp;
                              <span className="desc" style={{ color: 'red' }}>
                                No approver found! Please contact HR.
                              </span>
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              className="desc"
                              style={{
                                alignItems: 'baseline',
                                display: 'flex',
                              }}
                            >
                              <PersonIcon style={{ fontSize: 'small' }} />
                              &nbsp;
                              <span
                                style={{
                                  whiteSpace: 'normal',
                                  marginTop: '3px',
                                }}
                              >
                                {WorkFlowData?.WorkFlowCurrentApproversList?.map(
                                  (v, index) => (
                                    <>
                                      <Typography variant="caption">
                                        {v?.name}
                                        {index ===
                                        WorkFlowData
                                          ?.WorkFlowCurrentApproversList
                                          ?.length -
                                          1
                                          ? ''
                                          : ','}
                                      </Typography>
                                      &nbsp;
                                    </>
                                  )
                                )}
                              </span>
                            </span>
                          </>
                        )}
                      </>
                    </TimelineContent>
                  </CustomTimelineItem>
                ) : (
                  <CustomTimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        style={
                          WorkFlowData?.approval_status ===
                          ApprovalStatus.Approved
                            ? {
                                backgroundColor: 'green',
                              }
                            : WorkFlowData?.approval_status ===
                                ApprovalStatus.Rejected ||
                              WorkFlowData?.approval_status ===
                                ApprovalStatus.Cancelled
                            ? {
                                backgroundColor: 'red',
                              }
                            : {}
                        }
                      />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant="subtitle1">
                        <span
                          style={
                            WorkFlowData?.approval_status === 'Pending'
                              ? {
                                  color: '#ffb11f',
                                  fontSize: '12px',
                                }
                              : WorkFlowData?.approval_status === 'Rejected' ||
                                WorkFlowData?.approval_status === 'Cancelled'
                              ? {
                                  color: 'red',
                                  fontSize: '12px',
                                }
                              : {
                                  color: 'green',
                                  fontSize: '12px',
                                }
                          }
                        >
                          {WorkFlowData?.approval_status ===
                          ApprovalStatus.Withdraw
                            ? 'Approved'
                            : WorkFlowData?.approval_status}
                        </span>
                      </Typography>

                      <>
                        <PersonIcon style={{ fontSize: 'small' }} />
                        <Typography variant="caption">
                          {' '}
                          {WorkFlowData?.approval_status ===
                          ApprovalStatus.Cancelled
                            ? WorkFlowData?.Employee?.Contact?.FullName
                            : WorkFlowData?.ApprovedBy}{' '}
                        </Typography>{' '}
                        <br />
                        <EventIcon style={{ fontSize: 'small' }} />
                        <Typography variant="caption">
                          {' '}
                          {WorkFlowData?.ApprovalStatus ===
                          ApprovalStatus.Cancelled
                            ? WorkFlowData?.CancelledDate
                              ? dateFormat(
                                  new Date(WorkFlowData?.CancelledDate),
                                  'dd mmm yyyy h:MM TT'
                                )
                              : null
                            : WorkFlowData?.ApprovalDate
                            ? dateFormat(
                                new Date(WorkFlowData?.ApprovalDate),
                                'dd mmm yyyy h:MM TT'
                              )
                            : null}{' '}
                        </Typography>{' '}
                        <br />
                        <Typography variant="caption">
                          {' '}
                          {WorkFlowData?.RejectedRemarks}{' '}
                        </Typography>
                      </>
                    </TimelineContent>
                  </CustomTimelineItem>
                )}
              </Timeline>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => {
                    setWorkFlow(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  )
}
